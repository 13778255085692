@import "styles/base/main.scss";

.subsettings {
  padding: $gutter-component;
}

.dark_theme,
.pallete {
  &_tip {
    margin-bottom: $gutter-component !important;
  }
  &_switch {
    width: 100%;
    margin-left: 0 !important;
    justify-content: space-between;
  }
}

.pallete {
  &_select {
    margin-bottom: $gutter-component !important;
  }
  &_select_item {
    display: flex !important;
    align-items: center;
  }
}

.userinfo {
  display: flex;
  height: 85px;
  padding: $gutter-component;

  &_image {
    flex: 0 0 60px;
    margin-right: $gutter-component;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &_detail {
    flex: 0 0 200px;
    max-width: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
