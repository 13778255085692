@import "../base/palette.scss";
@import "../base/background-color.scss";

a {
  color: inherit;
  text-decoration: none;
}

html,
body {
  margin: 0;
  padding: 0 !important;
  height: 100%;
  width: 100%;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 14px;
}

input {
  font-family: "Roboto", sans-serif !important;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid transparent;
  -webkit-text-fill-color: inherit;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
}

ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}

##{$app-id}root {
  width: 100%;
  height: 100%;
  position: absolute;
}

.scrollbar,
.ReactVirtualized__List {
  overflow: auto;
  overflow-y: overlay !important;
  overflow-x: overlay !important;
  &:focus {
    outline: none;
  }
}

.scrollbar::-webkit-scrollbar,
.ReactVirtualized__List::-webkit-scrollbar,
body::-webkit-scrollbar {
  border-right: 1px solid $background-color-lv4;
  width: 3px;
  height: 3px;
}

.scrollbar::-webkit-scrollbar-thumb,
.ReactVirtualized__List::-webkit-scrollbar-thumb,
body::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.61);
  border-radius: 4px;
}

.scrollbar:hover::-webkit-scrollbar,
.ReactVirtualized__List:hover::-webkit-scrollbar,
body:hover::-webkit-scrollbar {
  background-color: $background-color-lv4;
}

.scrollbar:hover::-webkit-scrollbar,
.ReactVirtualized__List:hover::-webkit-scrollbar,
body:hover::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.ReactVirtualized__Grid {
  outline: none;
}
