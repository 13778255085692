@import "styles/base/main.scss";

.layout {
  display: flex;
  height: 100%;
  flex-direction: column;

  &__body {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
}
