@import "styles/base/main.scss";

.list {
  width: 100%;
  height: 100%;
}
.empty {
  padding: $gutter-component;

  &__row {
    height: 120px;
    margin-bottom: $gutter-component;
  }
}
.row {
  padding: $gutter-component/2 $gutter-component;

  &__body {
    height: 100%;
    padding: 0 $gutter-component;
  }
}

.user_info {
  padding: $gutter-component 0 $gutter-base 0;
  display: flex;
  align-items: flex-end;
  div {
    margin-right: $gutter-component;
  }

  &_time {
    font-style: italic;
  }

  &--receiver {
    justify-content: flex-end;
  }
}

.sender {
  margin-right: 10%;
  height: 100%;
}

.receiver {
  height: 100%;
  margin-left: 10%;
}
