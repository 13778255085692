@import "styles/base/main.scss";

.list {
  padding-bottom: $gutter-component;
  display: flex;
  height: 100%;
  flex-direction: column;

  &__count {
    display: inherit;
    align-items: center;
    h6:first-child {
      margin-right: $gutter-text;
    }
  }

  &__actions {
    flex: 0 0 55px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: $gutter-component $gutter-component 0 $gutter-component;
  }

  &__search {
    margin: $gutter-component;
    flex: 0 0 45px;
    width: calc(100% - 32px);
    display: flex;
    background: $background-color-lv0;
    border-radius: $border-radius-component;
    align-items: center;

    &_input {
      flex: 1;
      margin-left: $gutter-component;
    }

    &_divider {
      height: 21px;
    }
  }

  &__data {
    flex: 1;
  }
}
.empty {
  padding: $gutter-component;

  &__row {
    height: 120px;
    margin-bottom: $gutter-component;
  }
}
.row {
  padding: $gutter-component/2 $gutter-component;

  &--top {
    padding-top: 1px;
  }

  &--last {
    padding-bottom: 2px;
  }

  &__body {
    height: 100%;
    width: 100%;
    padding: $gutter-component;
    border-radius: $border-radius-component;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.02),
      0px 2px 2px 0px rgba(0, 0, 0, 0.1), 0px 3px 1px -2px rgba(0, 0, 0, 0.01);
    display: flex;
    align-items: center;
    cursor: pointer;
    background: $background-color-lv0;

    &:hover {
      background-color: $background-color-hover;
    }

    &--selected {
      background: $background-color-selected;
    }
  }
}
