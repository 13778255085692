@import "styles/base/main.scss";

.main {
  width: 400px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  padding: $gutter-base $gutter-component !important;
}

.body,
.button {
  padding: $gutter-component !important;
}
