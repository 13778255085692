$background-color-lv0: #ffffff;
$background-color-lv1: #fafafa;
$background-color-lv2: #f5f5f5;
$background-color-lv3: #eeeeee;
$background-color-lv4: #e0e0e0;
$background-color-lv5: #bdbdbd;
$background-color-lv6: #9e9e9e;
$background-color-lv8: #616161;
$background-color-lv9: #424242;
$background-color-lv10: #212121;

$background-color-hover: rgba(0, 0, 0, 0.08);
$background-color-active: rgba(0, 0, 0, 0.54);
$background-color-selected: rgba(0, 0, 0, 0.14);
