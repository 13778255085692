@import "styles/base/main.scss";

.root {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;

  &__table {
    flex: 1;
  }
}

.empty {
  padding: $gutter-component;

  &__row {
    height: 120px;
    margin-bottom: $gutter-component;
  }
}

.th {
  padding: 16px 16px 13px 16px;
  display: inline-block;
  border-bottom: $border-solid-1px;
}
.td--primary {
  font-weight: 500 !important;
}

.row {
  &__body {
    display: flex;
    align-items: center;
    cursor: pointer;
    border-bottom: $border-solid-1px;

    &:hover {
      background-color: $background-color-hover;
      border-color: transparent;
    }

    &--selected {
      background: $background-color-selected;
    }
    &--disable-click {
      cursor: unset;
    }
  }
}

.td {
  padding: $gutter-component;
}

.search {
  margin: $gutter-base 0;

  &_total {
    margin-right: $gutter-base !important;
  }

  &__filter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 250px;
    float: right;
    padding: 0 0 0 $gutter-component;
    border-radius: $border-radius-container;

    &_input {
      flex: 1;
    }
    &_divider {
      height: 20px !important;
    }
  }
}

.advanced_search {
  padding: $gutter-component;

  &_actions {
    text-align: right;
    margin-top: $gutter-component;
    button {
      margin-left: $gutter-component;
    }
  }
}

.empty {
  padding: $gutter-container;
}

.loading {
  z-index: 1;
  width: 100%;
  height: 100%;
  position: absolute;

  &__container {
    position: absolute;
    width: 200px;
    height: 2px;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    margin: auto;
  }

  &__loader {
    height: 100%;
    display: flex;
    transform: translateZ(0);

    div {
      flex: 1;
      animation: go 0.8s infinite alternate ease;
    }
    div:nth-child(1) {
      animation-delay: -0.72s;
    }
    div:nth-child(2) {
      animation-delay: -0.64s;
    }
    div:nth-child(3) {
      animation-delay: -0.56s;
    }
    div:nth-child(4) {
      animation-delay: -0.48s;
    }
    div:nth-child(5) {
      animation-delay: -0.4s;
    }
    div:nth-child(6) {
      animation-delay: -0.32s;
    }
    div:nth-child(7) {
      animation-delay: -0.24s;
    }
    div:nth-child(8) {
      animation-delay: -0.16s;
    }
    div:nth-child(9) {
      animation-delay: -0.08s;
    }
    div:nth-child(10) {
      animation-delay: 0s;
    }
  }
}

@-webkit-keyframes go {
  100% {
    background: transparent;
    flex: 10;
    box-shadow: 0 0 0 transparent;
  }
}

@keyframes go {
  100% {
    background: transparent;
    flex: 10;
    box-shadow: 0 0 0 transparent;
  }
}
